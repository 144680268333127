import React, {useState, useEffect} from 'react';
import axios from "axios";
import Button from '@material-ui/core/Button';
import Amplify, { API, Auth } from 'aws-amplify';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

/* function useAsyncEndpoint(fn) {
    const [res, setRes] = useState({
      data: null,
      headers: null,
      complete: false,
      pending: false,
      error: false
    });
    const [req, setReq] = useState();
  
    useEffect(
      () => {

        if (!req) return;
        setRes({
          data: null,
          headers: null,
          pending: true,
          error: false,
          complete: false
        });
        axios(req)
          .then(res =>
            setRes({
              data: res.data,
              pending: false,
              error: false,
              complete: true
            })
          )
          .catch(() =>
            setRes({
              data: null,
              pending: false,
              error: true,
              complete: true
            })
          );
      },
      [req]
    );
  
    return [res, (...args) => setReq(fn(...args))];
  }

  const ApertureApi = 'https://aperture-api-dev.ingenta.dev/aperture/ApertureWebApis2/api'


  function getFromApi(idToken) {
    /* eslint-disable react-hooks/rules-of-hooks */
   /*  return useAsyncEndpoint(path => ({
      url: ApertureApi + path,
      method: "GET",
      headers: {'Authorization': 'Bearer ' + idToken}
    }));
  } */


export default function ApertureCall() {
  const classes = useStyles();
    const [idToken, setIdToken] = useState(null);
    
    //const [ApiRequest, getApiRequest] = getFromApi(idToken);
    
    
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tenantidList, settenantidList] = useState(0);
    const [currentTenant, setCurrentTenant] = useState(0);
    const [apiData, setApiData] = useState(0);

    useEffect(() => {

      async function getTenantList(){
        try {
            var session = await Auth.currentSession();
        }
        catch(e) { console.log('no session') }
        let myInit = {
            headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
        }
        const response = await API.get('tenants', '/tenantid', myInit)

                settenantidList(
                  response.Items.map((d)=> {
                return {name: d.tenantName, resTenantId: d.tenantid}
            }))
      }
        getTenantList();    
      }, []);
    
    useEffect(() => {
    try {
        setError(null);
        setLoading(true);
  
        Auth.currentSession().then(idToken => {
          setIdToken(idToken.idToken.jwtToken);
          console.log(idToken);
          //getApiRequest('/product/GetFeaturedProducts')
          // TBD
        }).catch(err => setError(err));
      }
      catch (e) {
        setError(e);
      }
      finally {
        setLoading(false);
      }
      },
       []
       );

    function CreateApiRequestGetContactLog() {
      /*   getApiRequest(
          '/cs/GetContactLog?ID=0'
        ) */
    }

    function CreateApiRequestGetProductSearch(tenant,searchQuery) {
/*       getApiRequest(
        '/product/GetProductSearch?SearchString='+searchQuery
      ) */
      callApertureAPI(tenant,'/product/GetProductSearch?SearchString='+searchQuery);
  }

  function CreateApiRequestGetCRMSearchDetail(tenant,roleId) {
 /*    getApiRequest(
      '

    ) */
    callApertureAPI(tenant,'/crm/GetCRMSearchDetail?roleId='+roleId);
}

async function callApertureAPI(tenantToAdmin,path) {

  try {
    var session = await Auth.currentSession();
    }
       catch(e) { console.log('no session') }
  let myInit = {
   headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
  }
  const response = await API.get('tenants', '/' + tenantToAdmin + '/aperture/ApertureWebApis2/api' + path, myInit);
  console.log(response);
  setApiData(response);
}

useEffect(() => {

  callApertureAPI("morgan",'/anonymousUser/GetFooterItems');    

}, []);


var submit = (values, actions) => { 
  //post(values);
  //setActiveStep(activeStep + 1)
  console.log(values.productSearch)
  values.productSearch ? CreateApiRequestGetProductSearch(values.tenant, values.productSearch) : 
  actions.setSubmitting(false);

 };

    return (
      <Formik initialValues={{}} onSubmit={submit} /* enableReinitialize={true} */ >
    <React.Fragment>
    <Form>
    <Field name="tenant" 
            className={classes.TextField}
            id="tenant"
            select
            label="Site"
            placeholder="Choose site"
            component={TextField} 
            variant="filled"
            margin="normal"
            defaultValue={tenantidList ? tenantidList[0].name : ""}
            >
            {Object.values(tenantidList).map((resultTenant, index) => (
            <MenuItem key={index} value={resultTenant.resTenantId}>
              {resultTenant.name}
            </MenuItem>
            ))}
            </Field>

        <Field name="productSearch" 
          
            id="productSearch"
            label="product search"
            placeholder="McDermott"
            component={TextField} 
            variant="filled"
            margin="normal"
            />
            <Button 
        /* onClick={CreateApiRequestGetProductSearch}  */
        type="submit"
        variant="contained"
        color="primary"
        >
            Product Search
        </Button>
        <Button 
        onClick={CreateApiRequestGetCRMSearchDetail} 
        
        variant="contained"
        color="primary"
        >
            CRM Search Detail
        </Button>
        </Form>
      
        <Container className={classes.cardGrid} maxWidth="md">

        <Grid container spacing={4}>
      {apiData.Products ? apiData.Products.map((product, key) => (
        <Grid item key={key} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
            <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {product.Name}
                    </Typography>
      <Typography variant="h6" gutterBottom>{product.Authors}</Typography>
                    <Typography noWrap='true'>
                      {product.Description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                  </CardActions>
                </Card>
          </Grid>
        ))

          : ""}  

          </Grid>
        </Container>

    </React.Fragment>
    </Formik>
    )
}