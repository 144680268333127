import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect, getIn } from 'formik';


const Confirm = props =>  {
    const tier = getIn(props.formik.values.tier, props.tier);
    const siteName = getIn(props.formik.values.siteName, props.siteName);
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>Please check your details are correct</Typography>
            <Typography>Site Name: {siteName}</Typography>
            <Typography>Plan level: {tier}</Typography>

        </React.Fragment>
    )

}
export default connect(Confirm);
