import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { TextField } from 'formik-material-ui';

import { Field } from 'formik';

export default function AddressForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Site Name
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field name="siteName" 
          label="Give your site a name" 
          placeholder="Site Name" 
          component={TextField} 
           
          required
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
