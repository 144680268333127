// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "client-20190610161550-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://client-20190610161550-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:e9b43903-8daa-4c15-95ed-e8489048378b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jxqLvspTB",
    "aws_user_pools_web_client_id": "4kloe35ehcsm3ak1agh8e091j1",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tenants-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "tenants",
            "endpoint": "https://rh8v1h6xz3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "userAdmin",
            "endpoint": "https://1w2xbqidn9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
