import React , { useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { API, Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      height: 56,
    },
    select: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    typography: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    TextField: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formcontrollabel: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    userAdminGrid: {
      justifySelf: 'end'
    }
  }));

  export default function InviteUser() {
    const classes = useStyles();
    const [resultTenants, setResultTenant] = useState(0);


    useEffect(() => {

        async function getTenantList(){
          try {
              var session = await Auth.currentSession();
          }
          catch(e) { console.log('no session') }
          let myInit = {
              headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
          }
          const response = await API.get('tenants', '/tenantid', myInit)
  
                  setResultTenant(
                    response.Items.map((d)=> {
                  return {name: d.tenantName, resTenantId: d.tenantid}
              }))
        }
          getTenantList();    
        }, []);


        async function post(values) {
            try {
              try { 
                      var session = await Auth.currentSession()
                       }
                         catch(e) { console.log('no session') } 
                let myInit = { 
                headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
                body: {
                  userName: values.userEmailInvite,
                  }
                }
        
                const postResponse = await API.post('tenants', '/'+ values.invitedSite + "/invite-user", myInit);
                //setSiteName(postResponse.newTenantAddress)
                console.log(postResponse)
        
            }
            catch(e) {console.log(e)}
          }

    var submit = (values, actions) => {
    
        post(values);
        //setActiveStep(activeStep + 1)
    
        actions.setSubmitting(false);
        
    
    
       };

    return (
        <Formik initialValues={{}} onSubmit={submit} /* enableReinitialize={true} */ >
        <React.Fragment>
            <CssBaseline />
                <main className={classes.layout}>
        <Paper className={classes.paper}>
        
        <Typography component="h1" variant="h4" align="center">Invite Users</Typography>
        <Form>
        <Field name="userEmailInvite" 
            className={classes.TextField}
            id="email"
            label="email"
            placeholder="user@example.com"
            component={TextField} 
            variant="filled"
            margin="normal"
            />
            
        <Field name="invitedSite" 
            className={classes.TextField}
            id="tenant"
            select
            label="Site"
            placeholder="Choose site"
            component={TextField} 
            variant="filled"
            margin="normal"
            defaultValue=""
            >
            {Object.values(resultTenants).map((resultTenant, index) => (
            <MenuItem key={index} value={resultTenant.resTenantId}>
              {resultTenant.name}
            </MenuItem>
            ))}
            </Field>
            <Button type="submit" variant="contained"
              color="primary"  className={classes.button}> 
              Invite
            </Button>
        </Form>            
        </Paper>
        </main>
        </React.Fragment>
        </Formik>
    );
  }