import React , { useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { API, Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CircularProgress  from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 56,
  },
  select: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  typography: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  TextField: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },
  formcontrollabel: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  userAdminGrid: {
    justifySelf: 'end'
  }
}));
 
export default function UserAdmin() {
  const classes = useStyles();
    const [resultTenants, setResultTenant] = useState(0);

    const [expanded, setExpanded] = React.useState(false);
    
    const [isLoaded, setisLoaded] = React.useState(false);

    const [showUserAdmin, setShowUserAdmin] = React.useState(false);

    const [userList, setUserList] = useState()

    const [tenantDetails, setTenantDetails] = React.useState()

    const handleChange = panel => (event, isExpanded) => {
      setisLoaded(false);
      setExpanded(isExpanded ? panel : false);
      setTenantDetails();
      getTenantDetails(panel);
    };

    useEffect(() => {

      async function getUsersForAdmin(){
        try {
            var session = await Auth.currentSession();
        }
        catch(e) { console.log('no session') }
        let myInit = {
            headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
        }
        const response = await API.get('tenants', '/tenantid', myInit)

                setResultTenant(
                  response.Items.map((d)=> {
                return {name: d.tenantName, resTenantId: d.tenantid}
            }))
      }
        getUsersForAdmin();    
      }, []);
      
      // const [activeUserList, setActiveUserList] = React.useState();
      
      const handleUserAdminClick = (tenantToAdmin) => {
        // setActiveUserList(tenantToAdmin);
        getTenantUsers(tenantToAdmin);
      };

      /* const handleTenantDetailsClick = (tenantToAdmin) => {
        setActiveUserList(tenantToAdmin);
        getTenantDetails(tenantToAdmin);
      }; */
        
      async function getTenantUsers(tenantToAdmin) {

        try {
          var session = await Auth.currentSession();
          }
             catch(e) { console.log('no session') }
        let myInit = {
         headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
        }
        const response = await API.get('tenants', '/' + tenantToAdmin + '/list-users', myInit);
        //console.log(response);

        var attributes = response.data.map(d => d.Attributes);

        var filteredAttributes = attributes.map((x) => 
         
         x.filter((a) => {
            return a.Value.includes(tenantToAdmin) & !a.Name.includes("tenantid") || a.Name==="email"
          })
        
        

        )

        var reducedAttributes = filteredAttributes.map((x) =>
        x.reduce( (total, current) => {
          total[ current.Name ] = current.Value;
          return total;
        },[])
        )

        console.log(reducedAttributes);

        var userAndRoles = reducedAttributes.map((y) => 
        {
          return [{email: y.email} , {role: Object.keys(y).filter(i =>y[i].includes(tenantToAdmin))} ]
        }
        
        )

        console.log(userAndRoles);

        setUserList(userAndRoles)

        setShowUserAdmin(true);
      }

      async function getTenantDetails(tenantToAdmin) {

        try {
          var session = await Auth.currentSession();
          }
             catch(e) { console.log('no session') }
        let myInit = {
         headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
        }
        const response = await API.get('tenants', '/' + tenantToAdmin + '/list-tenants', myInit);
        console.log(response);
        setTenantDetails(response);
        setisLoaded(true);
      }

      var submit = (values, actions) => {
    
        //post(values);
        //setActiveStep(activeStep + 1)
    
        actions.setSubmitting(false);
        
    
    
       };
      
  return (
  <Formik initialValues={{}} onSubmit={submit} /* enableReinitialize={true} */ >
    <React.Fragment>
{/*       <Typography variant="h6" gutterBottom>
        Select Site
      </Typography>
      <Grid container spacing={1}>
      {Object.values(resultTenants).map((resultTenant, index) => (
        <Grid item xs={8} sm={12} key={index}>
          {resultTenant.name}
          <Button type="button" variant="contained"
            onClick={() => handleUserAdminClick(resultTenant.resTenantId)}
            color="primary" > 
          Manage Users
          </Button>
          <Button type="button" variant="contained"
            onClick={() => handleTenantDetailsClick(resultTenant.resTenantId)}
            color="primary" > 
            Show details
          </Button>
          </Grid>
      ))} 
        {JSON.stringify(activeUserList, null, 2)}
      </Grid> */}
      
      <CssBaseline />
      <main className={classes.layout}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center"> Select Site</Typography>
{/*       <List component="nav" aria-label="select site">
      {Object.values(resultTenants).map((resultTenant, index) => (
        <ListItem button onClick={() => handleTenantDetailsClick(resultTenant.resTenantId)} key={index}>
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
              <ListItemText primary={resultTenant.name}/>
        </ListItem>
      ))}
      </List> */}
      <Form>
      {Object.values(resultTenants).map((resultTenant, index) => (
      <ExpansionPanel key={index} expanded={expanded === resultTenant.resTenantId} onChange={handleChange(resultTenant.resTenantId)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>{resultTenant.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {isLoaded ? 

            (
            <React.Fragment>
              <Grid container spacing={3} justify="space-evenly" direction="row">
              <Grid item>
            <Field name="siteNameToChange" 
            className={classes.TextField}
            id="Name"
            label="Name"
            defaultValue={tenantDetails[0].tenantName}
            placeholder={tenantDetails[0].tenantName}
            component={TextField} 
            variant="filled"
            margin="normal"
            
            /></Grid>
            
            <Grid item>
            <Field name="siteTierToChange" 
            className={classes.TextField}
            id="Tier"
            select
            label="Tier"
            defaultValue={tenantDetails[0].tenantTier}
            placeholder={tenantDetails[0].tenantTier}
            component={TextField} 
            variant="filled"
            margin="normal"
            >
              {["Silver", "Gold", "Enterprise"].map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Field>
            </Grid>
            <Grid item>
            <Button type="submit" variant="contained"
              color="primary"  className={classes.button}> 
              Update
              </Button>
            </Grid>
            
            <Grid item>
            <Button type="button" variant="contained"
              color="primary"  className={classes.button} onClick={() => handleUserAdminClick(resultTenant.resTenantId)}> 
              Manage Users
              </Button>
            </Grid>

            </Grid>
            </React.Fragment>
            ) 
            :(
            <React.Fragment>
              <CircularProgress  />
            </React.Fragment>  
            )
            }
          
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      ))}
      </Form>
      </Paper >
      {showUserAdmin ? 
      (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center"> User Admin</Typography>
        <Grid container spacing={3} justify="space-evenly" direction="row">
              
      <FormGroup row>
        <List>
        {Object.values(userList).map((user, index) => (
          <React.Fragment key={index}>
        <ListItem >
        <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          
          <ListItemText primary={user[0].email} />
          
           <Field name="userRole" 
           className={classes.TextField}
            id="role"
            select
            label="Role"
            defaultValue={user[1].role.toString().slice(7)}
            placeholder={user[1].role.toString().slice(7)}
            component={TextField} 
            variant="filled"
            margin="normal"
            >
              {["rep", "product", "admin"].map(option => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
            </Field>
            <Button type="submit" variant="contained"
              color="primary"  className={classes.button}> 
              Update
            </Button>
            
          </ListItem>
          </React.Fragment>
        )) }
        </List>
      </FormGroup></Grid>
      </Paper>
      )
      :"" 
      }
      </main>
    </React.Fragment></Formik>
  );
}
