import React, {useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Pricing from './pricing';
import Confirm from './Confirm';

import { Formik, Form } from 'formik';
import Amplify, { API, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Site Name', 'Usage', 'Pricing', 'Confirm'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <PaymentForm />;
    case 2: 
      return <Pricing />;
    case 3:
      return <Confirm />;

    default:
      throw new Error('Unknown step');
  }
}

export default function NewSiteSignUp() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [newSiteName, setSiteName]= useState();
  
  async function post(values) {
    try {
      try { 
              var session = await Auth.currentSession()
               }
                 catch(e) { console.log('no session') } 
        let myInit = { 
        headers: {'customauthorization': session.idToken.jwtToken, 'customaccessToken': session.accessToken.jwtToken},
        body: {
          tenantName: values.siteName,
          tenantTier: values.tier,
          tenantUsageText: values.siteDetails
          }
        }

        const postResponse = await API.post('tenants', '/tenantid', myInit);
        setSiteName(postResponse.newTenantAddress)

        //Refresh users session after post to pickup changes to claims
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            console.log('session', err, session);
            //const { idToken, refreshToken, accessToken } = session;
          
          });
        } catch (e) {
          console.log('Unable to refresh Token', e);
        }

    }
    catch(e) {console.log(e)}
  }

  
  var submit = (values, actions) => {
    
    post(values);
    setActiveStep(activeStep + 1)

    actions.setSubmitting(false);
    


   };



  return (
    <Formik initialValues={{tier: "Gold", siteName:"", siteDetails: ""}} onSubmit={submit} /* enableReinitialize={true} */ >
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Form>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Create site
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you choosing Manifold
                </Typography>
                    <Typography variant="subtitle1">   
                        Your new site is <b>{newSiteName}</b> You will recieve an email shortly confirming its availabilty.
                    </Typography>
                    <Link color="inherit" href="/">Home</Link>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && ( 
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    //onClick={handleNext}
                    className={classes.button}
                  >
                    Build Site
                  </Button>
                  )} 
                  {activeStep !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
                  )}
                </div>
              </React.Fragment> )}
          </React.Fragment>
        </Paper>
        </Form>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
    </Formik>
  );
}